import { ContentPageLayout } from '@components/Layout';
import React, { FC, useEffect, useMemo } from 'react';
import queryString from 'query-string';
import { observer } from 'mobx-react';
import { useStores } from '../../stores/index';
import { Link } from '@components/Primitives';
import { navigate } from 'gatsby-plugin-intl';

interface IEventsPage {
  location: any;
}

const EventsPage: FC<IEventsPage> = observer(({
  location,
}) => {
  const { event } = queryString.parse(location.search);
  const {
    eventStore: { getEventRegistrations, eventRegistrations },
    agreementStore: { selectedAgreementId }
  }: {
    eventStore: IEventStore,
    agreementStore: IAgreementStore,
  } = useStores();

  const eventRegistration = useMemo(() => {
    if (!event || !eventRegistrations) return undefined;
    return eventRegistrations?.find((eventRegistration) => eventRegistration.eventid === parseInt(event as string));
  }, [event, eventRegistrations]);

  useEffect(() => {
    if (event && selectedAgreementId) {
      // console.log(event, selectedAgreementId)
      getEventRegistrations(event as string)
    }
  }, [event, selectedAgreementId]);

  useEffect(() => {
    if (!event) navigate('/');
  }, [event]);

  return (
    <ContentPageLayout wideContent>
      <Link underline to={`/events/?event=${event}`}>Palaa takaisin</Link>
      {eventRegistration?.faq && <div dangerouslySetInnerHTML={{ __html: eventRegistration?.faq }} />}
    </ContentPageLayout>
  )
});


export default EventsPage;
